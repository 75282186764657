<template>
  <el-tabs
    v-model="editableTabsValue"
    type="card"
    @tab-remove="removeTab"
    @tab-click="clickTab"
  >
    <el-tab-pane
      :closable="item.name != 'Index'"
      v-for="item in editableTabs"
      :key="item.name"
      :label="item.title"
      :name="item.name"
    >
    </el-tab-pane>
  </el-tabs>
</template>

<script>
export default {
  name: "Tabs",
  data() {
    return {
      editableTabsValue: this.$store.state.menus.editableTabsValue,
      editableTabs: this.$store.state.menus.editableTabs,
      selectTabs: "",
    };
  },
  watch: {
    "$store.state.menus.editableTabs"(val) {
      this.editableTabs = val;
    },
    "$store.state.menus.editableTabsValue"(val) {
      this.editableTabsValue = val;
    },
  },
  computed: {},
  methods: {
    removeTab(targetName) {
      let tabs = this.editableTabs;
      let activeName = this.editableTabsValue;

      if (activeName === "Index") {
        return;
      }

      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.name === targetName) {
            let nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              activeName = nextTab.name;
            }
          }
        });
      }

      this.editableTabsValue = activeName;
      let item_tab = tabs.find((e) => e.name === targetName);
      this.$store.commit("removeTab", item_tab);
      this.$store.commit("setTabName", activeName);
      this.$router.push({ name: activeName });
    },
    clickTab(target) {
      if (this.selectTabs != target.name) {
        this.selectTabs = target.name;
        this.$store.commit("setTabName", target.name);
        this.$router.push({ name: target.name });
      }
    },
  },
};
</script>

<style scoped></style>
